<template>
  <div class="home container-fluid">


    <LastCircularNotification />

    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
      <!-- <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" class="active"
          aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1"
          aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="2"
          aria-label="Slide 3"></button>
      </div> -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="../assets/imgColvm/img (1).jpg"
            type="button"
            class="d-block btn-img w-100 img-fluid rounded shadow"
            @click.prevent="openForm2"
            alt=""
          />
        </div>
        <div class="carousel-item">
        <div class="ratio ratio-16x9">
          <iframe
            class="embed-responsive-item"
            src="https://www.youtube.com/embed/VYdEMb5efx8?si=B1aUqADfl8fnhPXG"
            title="YouTube video"
            allowfullscreen
          ></iframe>
        </div>
      </div>
        <div class="carousel-item">
          <img src="../assets/imgColvm/img (2).png" class="d-block w-100" alt="" />
        </div>
        <div class="carousel-item">
          <img src="../assets/imgColvm/img (3).jpg" class="d-block w-100" alt="" />
        </div>
        <div class="carousel-item">
          <img src="../assets/imgColvm/img (4).jpg" class="d-block w-100" alt="" />
        </div>
       
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Anterior</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Siguiente</span>
      </button>
    </div>

    <div>
      <section id="quienes-somos" class="py-4 bg-light">
        <!-- <div class="card mb-3 mx-3">
          <AppInstagram></AppInstagram>

        </div> -->
        <div class="card mx-3">

          <div class="card-header">
            <h4 class="card-title fw-bold">¿Quienes Somos?</h4>
            <h5>Colegio Visión Mundial</h5>
          </div>
          <div class="row">
          <div class="col-md-4 position-relative">
            <!-- Imagen de fondo -->
            <div class="bg-container" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1;">
              <img
                src="../assets/imgColvm/quienesomos.jpg"
                alt="Imagen de fondo"
                class="img-fluid imgS h-100 w-100"
                style="object-fit: cover; opacity: 0.5;"
              />
            </div>

            <!-- Contenedor para centrar el video -->
            <div class="d-flex justify-content-center align-items-center" style="position: relative; z-index: 2; height: 100%;">
              <div class="ratio ratio-16x9" style="max-width: 100%;">
                <iframe
                  src="https://www.youtube.com/embed/H3dbCi_rRu4?si=Nlm09dns44nP2T8D"
                  title="YouTube video"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
            <div class="col-md-8">
              <div class="card-body">
                <p class="card-text my-2 d-none d-md-block">
                  <!-- Visible solo en pantallas medianas o mayores -->
                  Somos un proyecto educativo que pertenece a la Organización World Vision y que
                  viene haciendo impacto social, espiritual, cultural, deportivo, científico y en
                  general en todos los ámbitos de la educación en la ciudad de Montería; Córdoba.
                  Estamos clasificados en Categoría A+ (Muy Superior) otorgada por el Instituto
                  Colombiano para la Evaluación de la Educación ICFES. <br /><br />

                  El Colegio Visión Mundial inició su proceso a inicios del año 2002 ofreciendo un
                  servicio educativo para primaria. Ha sido muy gratificante la evolución del
                  colegio que en el año 2021 cuenta con grado preescolar hasta grado once. Son 21
                  años brindando un servicio con calidad promoviendo el desarrollo y transformación
                  en la vida de los niños, niñas, adolescentes y jóvenes (NNAJ), para que
                  contribuyan de manera significativa en la transformación de la sociedad y en la
                  conservación del medio ambiente. Contamos con personal docente y administrativo
                  altamente calificado, con sentido humano y disposición al servicio. <br /><br />La
                  Institución cuenta con una infraestructura amigable a toda la comunidad donde se
                  generan espacios para el debido esparcimiento y orientaciones de clases. Asimismo,
                  hay organizado todo un sistema de aulas virtuales con recursos interactivos,
                  material de trabajo, clases y evaluaciones en línea que garantizan una educación
                  integral y acorde a los nuevos modelos educativos virtuales y de alternancia.
                  <br /><br />

                  Son muchas las proyecciones que se realizarán para el año 2024, entre las que se
                  tiene aumentar la población estudiantil en el colegio Visión Mundial, al igual que
                  establecer más alianzas estratégicas con el sector productivo, seguir trabajando
                  en las estrategias planeadas en el 2023 y de esta manera fortalecer la gestión
                  educativa.
                </p>
                <p class="card-text my-2 d-block d-md-none">
                  <!-- Visible solo en pantallas pequeñas -->
                  Somos un proyecto educativo que pertenece a la Organización World Vision y que
                  viene haciendo impacto social, espiritual, cultural, deportivo, científico y en
                  general en todos los ámbitos de la educación en la ciudad de Montería; Córdoba.
                  <span id="more-text" class="collapse">
                    Estamos clasificados en Categoría A+ (Muy Superior) otorgada por el Instituto
                    Colombiano para la Evaluación de la Educación ICFES. <br /><br />

                    El Colegio Visión Mundial inició su proceso a inicios del año 2002 ofreciendo un
                    servicio educativo para primaria. Ha sido muy gratificante la evolución del
                    colegio que en el año 2021 cuenta con grado preescolar hasta grado once. Son 21
                    años brindando un servicio con calidad promoviendo el desarrollo y
                    transformación en la vida de los niños, niñas, adolescentes y jóvenes (NNAJ),
                    para que contribuyan de manera significativa en la transformación de la sociedad
                    y en la conservación del medio ambiente. Contamos con personal docente y
                    administrativo altamente calificado, con sentido humano y disposición al
                    servicio. <br /><br />La Institución cuenta con una infraestructura amigable a
                    toda la comunidad donde se generan espacios para el debido esparcimiento y
                    orientaciones de clases. Asimismo, hay organizado todo un sistema de aulas
                    virtuales con recursos interactivos, material de trabajo, clases y evaluaciones
                    en línea que garantizan una educación integral y acorde a los nuevos modelos
                    educativos virtuales y de alternancia. <br /><br />

                    Son muchas las proyecciones que se realizarán para el año 2024, entre las que se
                    tiene aumentar la población estudiantil en el colegio Visión Mundial, al igual
                    que establecer más alianzas estratégicas con el sector productivo, seguir
                    trabajando en las estrategias planeadas en el 2023 y de esta manera fortalecer
                    la gestión educativa.
                  </span>
                </p>
                <button
                  class="btn btn-primary d-md-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#more-text"
                  aria-expanded="false"
                  aria-controls="more-text"
                >
                  Leer más
                </button>
              </div>
            </div>
          
          </div>
        </div>

  
      </section>

      <section id="mision-vision" class="pt-4">
        <div class="container-fluid">
          <div class="row justify-content-center card-group align-items-center">
            <!-- Columna Misión -->
            <div class="col-md-6">
              <div class="card border-warning mb-3">
                <div class="card-header fs-2 text-center">Misión</div>
                <div class="card-body">
                  <!-- <h5 class="card-title">Warning card title</h5> -->
                  <p class="card-text text-justify">
                    Formar de manera integral a NNAJ residentes en la ciudad de Montería, con un
                    perfil Técnico en Sistemas, orientados con principios cristianos, ambientales,
                    culturales, científicos, tecnológicos, deportivos y recreativos. Respondiendo,
                    de esta manera, a un ideal de un nuevo ciudadano colombiano que pueda aprender a
                    ser, aprender a aprender, aprender a hacer y aprender a convivir con sus deberes
                    y derechos; promoviendo así el desarrollo y la transformación humana.
                  </p>
                </div>
              </div>
            </div>

            <!-- Columna Visión -->
            <div class="col-md-6">
              <div class="card border-info mb-3">
                <div class="card-header fs-2 text-center">Visión</div>
                <div class="card-body">
                  <!-- <h5 class="card-title">Warning card title</h5> -->
                  <p class="card-text text-justify">
                    Ser una Institución Certificada en Procesos de Calidad para el año 2030, que
                    ofrece el Servicio de Educación con el nivel Técnico en Sistemas a la sociedad
                    Monteriana, así mismo, ser una institución que evidencia resultados de la
                    educación con ternura, el bilingüismo y que trabaja todos sus procesos bajo el
                    concepto de colegio verde y la autosostenibilidad.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <AlianzaSection />
      
    </div>
  </div>
</template>

<script>
import LastCircularNotification from '@/components/LastCircularNotification.vue';
import AlianzaSection  from '@/components/AlianzaSection.vue';

export default {
 
  name: "HomeView",
  components: {
    LastCircularNotification,
    AlianzaSection,
    
    
  },
  methods: {
    openForm2() {
      window.open("https://forms.gle/4XMCPk4hyTBcgRpg6", "_blank", "width=800,height=1000");
    },
  },
};
</script>

<style scoped>
.home {
  padding-right: 0px;
  padding-left: 0px;
}

p {
  text-align: justify;
}

.imgS {
  object-fit: cover;
  /*La imagen se ajustará al contenedor y puede ser recortada para llenar el espacio */
}

.btn-img {
  opacity: 0.95;
  transition: all 0.5s;
}

.btn-img:hover {
  rotate: calc(0.5deg);
  opacity: 1;
}

.bg-card {
  background-color: #a03232;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%), inset 0 -1px 0 rgb(0 0 0 / 15%);
}
</style>
