<template>
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div  id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true" ref="toast">
    <div class="toast-header">
      <!-- <img src="" class="rounded me-2" alt="..."> -->
     <div class="me-auto" type="button" @click="goToCircular">
      <i class="bi bi-eye"></i> ¡ATENCIÓN! NUEVA CIRCULAR N° {{ lastCircular.numero }}

     </div>
  
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
   
  </div>
</div>


  </template>
  
  <script>
  import { Toast } from "bootstrap";
  
  export default {
    data() {
      return {
        lastCircular: {
          numero: '15',
          id: 15,
        },
      };
    },
    mounted() {
      const toastElement = this.$refs.toast;
      const toastInstance = new Toast(toastElement, {
        autohide: true, // Se ocultará automáticamente después de unos segundos
        delay: 10000, // Tiempo en milisegundos (5 segundos)
      });
      toastInstance.show(); // Mostrar el toast automáticamente al montarse
    },
    methods: {
      goToCircular() {
        this.$router.push({
          name: "ComunicacionesView", // Redirige a la vista de las circulares
          params: { id: this.lastCircular.id },
        });
      },
    },
  };
  </script>
  
  <style scoped>

.toast {
  min-width: 100px;
  background-color: var(--color-co); /* Fondo blanco para el toast */
  color: var(--color-se); /* Texto en color azul */
}

.toast-header {
  background-color: var(--color-pr); /* Fondo naranja para el header */
  color: var(--color-co); /* Texto en blanco */
  border-bottom: 1px solid var(--color-se); /* Línea de separación en azul */
}

.toast-body {
  background-color: var(--color-co); /* Fondo blanco para el cuerpo del toast */
  color: var(--color-se); /* Texto en azul */
}

.btn-close {
  filter: invert(1); /* Invertir los colores del botón de cerrar para que sea visible sobre fondo naranja */
}
  </style>